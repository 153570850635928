import React from "react";
import PropTypes from "prop-types";
import TextInfo, {
  TEXT_COLOR,
  TEXT_OPACITY,
  TEXT_FONT_WEIGHT,
  TEXT_KIND,
  TEXT_ALIGN,
  TEXT_BORDER,
} from "@/bit/components/components.text";
import { LABEL_TYPE, LABEL_FUNC } from "./enums";

export { LABEL_TYPE };

const labelThemes = {
  promo: { color: TEXT_COLOR.PURPLE, background: TEXT_COLOR.PURPLE },
  active: { color: TEXT_COLOR.WHITE, background: TEXT_COLOR.BLUE },
  inactive: { color: TEXT_COLOR.GREY4, background: TEXT_COLOR.GREY4 },
  success: { color: TEXT_COLOR.GREEN, background: TEXT_COLOR.GREEN },
  warning: { color: TEXT_COLOR.ORANGE, background: TEXT_COLOR.ORANGE },
  error: { color: TEXT_COLOR.RED, background: TEXT_COLOR.RED },
  discount: { color: TEXT_COLOR.WHITE, background: TEXT_COLOR.RED_DARK },
  gift: { color: TEXT_COLOR.WHITE, background: TEXT_COLOR.BLUE_DARK },
  spent: { color: TEXT_COLOR.WHITE, background: TEXT_COLOR.GREY5 },
};

// Expresión regular para encontrar un numero y redondear los decimales
function redondearPrecio(texto) {
  const regex = /-?\d+(\.\d+)?/;
  const match = texto.match(regex);

  if (match) {
    const numero = parseFloat(match[0]);
    const tieneDecimales = match[0].includes('.');
    const numeroRedondeado = tieneDecimales ? numero.toFixed(2) : numero.toString();
    return texto.replace(match[0], numeroRedondeado);
  }

  return texto;
}


/**
 * Elemento de interfaz que muestra texto para describir o identificar otros componentes. No es interactivo, pero proporciona información útil al usuario.
 */
const Label = (props) => {
  const {
    inversed,
    layout,
    notBorderRadius,
    customColors,
    className,
    cms,
    cmsHandler = null,
    func,
    oper1,
    oper2,
    cyData,
  } = props;
  
  let { label, theme } = props;

  if (!label) {
    if (cmsHandler) {
      // Placeholder cms
      label = "Label";
      theme = "inactive"
    } else return null;
  }
  
  const finalInversed = inversed && (inversed === true || inversed === "true") ? true : false
  const finalNotBorderRadius = notBorderRadius && (notBorderRadius === true || notBorderRadius === "true") ? true : false 

  const {
    color: customColor = customColors?.color,
    background: customBackground = customColors?.background,
  } = customColors;

  const {
    color: themeColor = labelThemes[theme]?.color || TEXT_COLOR.PURPLE,
    background: themeBackground = labelThemes[theme]?.background ||
    TEXT_COLOR.PURPLE,
  } = labelThemes[theme] || {};

  const labelColor = customColor || themeColor;
  const labelBg = finalInversed
    ? TEXT_COLOR.WHITE
    : customBackground || themeBackground;

  if (func) {
    try {
      if (func === LABEL_FUNC.SAVING_VS_FREE) {
        let result = Math.round(
          parseFloat(oper1.replace(",", ".")) -
          parseFloat(oper2.replace(",", ".")) * 48 -
          1
        );
        label = label.replace("{func}", result);
      }
    } catch {
      label = "";
    }
  }

  let currentClassName = `label ${className} label-theme--${theme || "custom"}${finalInversed ? "--inversed" : ""
    } layout--${layout} border-radius--${finalNotBorderRadius} text-content`;

  const renderTextInfo = () => (
    <>
      <TextInfo
        text={redondearPrecio(label)}
        // text={label}
        color={labelColor}
        backgroundColor={labelBg}
        kind={TEXT_KIND.SPAN}
        size="caption"
        fontType={TEXT_FONT_WEIGHT.REGULAR}
        align={TEXT_ALIGN.CENTER}
        border={finalNotBorderRadius ? TEXT_BORDER.NONE : TEXT_BORDER.ROUNDED}
        bgOpacity={finalNotBorderRadius ? TEXT_OPACITY[0] : TEXT_OPACITY[10]}
        className={currentClassName}
        cyData={cyData}
      />
      <style jsx>{`:global(.label) {
  padding: 0.25rem 0.75rem;
  min-width: 3.5rem;
  width: fit-content;
  height: fit-content;
}

:global(.label.layout--fantasy) {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.1rem 1rem !important;
  border-radius: 1rem 0;
  z-index: 20;
}

:global(.label.layout--full) {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.1rem 1rem !important;
  border-radius: 0;
  z-index: 20;
  width: 100%;
}
`}</style>
    </>
  );

  if (cmsHandler) {
    currentClassName = `${currentClassName} element-editable`;
    return (
      <div
        className={currentClassName}
        onClick={
          cms && cmsHandler
            ? () => cmsHandler({ ...props, name: 'label' })
            : null
        }
      >
        {renderTextInfo()}
      </div>
    );
  }
  return (
    <>
      {renderTextInfo()}
    </>
  );
};

Label.propTypes = {
  /**
   * {
   *  "info": "Define el tema del label y puede ser uno de los siguientes valores: 'promo', 'active', 'inactive', 'success', 'warning' o 'error'.",
   *  "kind": "both",
   *  "beautifulName": "Theme",
   *  "values": "./enums.js:LABEL_TYPE"
   * }
   */
  theme: PropTypes.string,
  /**
   * {
   *  "info": "Define cómo se posiciona el label y puede ser uno de los siguientes valores: 'default', 'full' o 'fantasy'.",
   *  "kind": "both",
   *  "beautifulName": "Layout",
   *  "values": "./enums.js:LABEL_LAYOUT"
   * }
   */
  layout: PropTypes.string,

  /**
   * {
   *  "info": "Texto del label.",
   *  "kind": "both",
   *  "beautifulName": "Label"
   * }
   */
  label: PropTypes.string.isRequired,

  /**
   * {
   *  "info": "Si es verdadero, el fondo será blanco, pero manteniendo el color del texto del tema.",
   *  "kind": "both",
   *  "beautifulName": "Inversed"
   * }
   */
  inversed: PropTypes.bool,

  /**
   * {
   *  "info": "Si es verdadero, los bordes no son redondeados.",
   *  "kind": "both",
   *  "beautifulName": "NotBorderRadius"
   * }
   */
  notBorderRadius: PropTypes.bool,

  /**
   * {
   *  "info": "Objeto que se utiliza para añadir colores personalizados al label. Debe tener dos propiedades opcionales: 'color' (el color del texto personalizado) y 'background' (el color de fondo personalizado).",
   *  "kind": "dev",
   *  "beautifulName": "CustomColors"
   * }
   */
  customColors: PropTypes.shape({
    color: PropTypes.string,
    background: PropTypes.string,
  }),

  /**
   * {
   *  "info": "Clase para añadirle estilos extras al icono.",
   *  "kind": "dev",
   *  "beautifulName": "ClassName"
   * }
   */
  className: PropTypes.string,

  /**
   * {
   *  "info": "Datos de prueba para Cypress.",
   *  "kind": "dev",
   *  "beautifulName": "CyData"
   * }
   */
  cyData: PropTypes.string,
  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
};

Label.defaultProps = {
  theme: "",
  layout: "default",
  inversed: false,
  notBorderRadius: false,
  className: "",
  customColors: {},
  cyData: "",
  cms: null,
  cmsHandler: null,

};

export default Label;
