import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Point from "./point";

const StyledPointList = styled.div.attrs((props) => {
  return {
    "data-cy": `${props.cyData || ""}`,
  };
})`
  .point-list {
    display: none;
    .point {
      border-radius: 100%;
      transition: all 300ms ease-in-out;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.breakpoint}px) {
    .point-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      gap: ${(p) => p.radius * 1.1}px;
      padding-top: 1rem;
      padding-bottom: 0.2rem;
    }
  }
`;

const PointList = ({
  points,
  onClick,
  activePoint,
  breakpoint,
  radius,
  className,
}) => {
  return (
    <StyledPointList radius={radius} breakpoint={breakpoint}>
      <div className="point-list">
        {Array(points)
          .fill()
          .map((e, i) => (
            <Point
              onClick={() => onClick(i)}
              key={`item-${i}`}
              isActive={i === activePoint}
              radius={radius}
              cyData={`${className} Point Point-${i}`}
            />
          ))}
      </div>
    </StyledPointList>
  );
};

export default PointList;

PointList.propTypes = {
  /**
   * {
   *  "info": "Número de puntos que se han de pintar",
   *  "kind": "both",
   *  "beautifulName": "Número de puntos",
   * }
   */
  points: PropTypes.number,

  /**
   * {
   *  "info": "Función que se pasa a cada punto y se ejecuta al clicar en estos",
   *  "kind": "both",
   *  "beautifulName": "Función al clicar los puntos",
   * }
   */
  onClick: PropTypes.func,

  /**
   * {
   *  "info": "Index (empezando por 0) del punto que se encuentra activo dentro de la lista",
   *  "kind": "both",
   *  "beautifulName": "Index de punto activo",
   * }
   */
  activePoint: PropTypes.number,
};

PointList.defaultProps = {
  points: 1,
  onClick: () => {},
  activePoint: 0,
  radius: 8,
};
